import React, { Component } from "react";
import { graphql } from "gatsby";
import { Link, StaticQuery } from "gatsby";

import MainLayout from "../layouts/MainLayout";
import Breadcrumb from "../components/common/breadcrumb";

import Recaptcha from "react-recaptcha";
import Seo from "../components/common/SEO";

import { encode } from "../utils";
import PageHeader from "../components/common/page-header";

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "init",
      formData: {
        firstName: "",
        lastName: "",
        contact: "",
        email: "",
        message: "",
        subject: "",
      },
    };
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      formData: { ...prevState.formData, [name]: value },
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let url = `${process.env.GATSBY_API_BASE_URL}/api/v1/marketplace/forms`;
    this.setState({ status: "submitting" });
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.GATSBY_API_ACCESS_TOKEN}`,
      },
      body: JSON.stringify({
        ...this.state.formData,
        contactRequest: {
          isContact: true,
          message: this.state.formData.message,
          subject: this.state.formData.subject,
        },
      }),
    })
      .then((res) => {
        this.setState({ status: "success" });
      })
      .catch((err) => {
        this.setState({ status: "failed" });
        console.log(err);
      });
    
  };

  render() {
    const { firstName, lastName, contact, email, message, subject } =
      this.state.formData;

    return (
      <MainLayout>
        <Seo title=" Contact Page" />
        <div className="main">
          <h1 className="d-none"> Contact Page</h1>
          <PageHeader title="Contact us " />

          <Breadcrumb title="Contact Us" adClass="border-0 mb-0" />

          {/* <div className="container">
            <div
              className="page-header page-header-big text-center"
              style={{
                backgroundImage: `url('assets/images/Jimenez-contact-us.jpg')`,
              }}
            >
              <h1 className="page-title text-white">
                Contact us
                <span className="text-white">keep in touch with us</span>
              </h1>
            </div>
          </div> */}
          <div id="map" className="mb-5">
            <iframe
              src="https://www.google.com/maps/d/u/0/embed?mid=16hE2oRjg_jGU4guKPkX9jMyfI4Od5Gwj"
              title="Where are we located?"
            ></iframe>
          </div>
          <div className="page-content pb-0">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <div className="contact-box text-center">
                    <i className="fa fa-building h1 text-white bg-primary rounded-circle p-3"></i>
                    <h3>Office Location</h3>
                    <a
                      href="https://maps.google.com/maps?q=Ohio+Wholesale+Liquidation+%26+Surplus"
                      target="__blank"
                    >
                      {" "}
                      <address>
                        Ohio Wholesale Liquidation and Surplus <br />
                        1245 Alum Creek Drive, Columbus, OH 43209
                      </address>
                    </a>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="contact-box text-center">
                    <i className="fa fa-star-o h1 text-white bg-primary rounded-circle p-3"></i>
                    <h3>Start a Conversation</h3>

                    <div>
                      <a href="mailto:contact@owls614.com">
                        contact@owls614.com
                      </a>
                    </div>
                    <div>
                      <a rel="norefferer" href="tel:614-549-6005">
                        +614-549-6005
                      </a>
                      ,{" "}
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="contact-box text-center">
                    <i className="fa fa-heart-o h1 text-white bg-primary rounded-circle p-3"></i>
                    <h3>Social</h3>
                    <StaticQuery
                      query={graphql`
                        {
                          contact {
                            email
                            phone
                            address
                            city
                            state
                            zip
                            facebook
                            instagram
                          }
                        }
                      `}
                      render={(data) => {
                        const { contact } = data;
                        return (
                          <div className="social-icons social-icons-color justify-content-center">
                            <a
                              href={`https://www.facebook.com/${contact.facebook}`}
                              className="social-icon social-facebook"
                              title="Facebook"
                              target="_blank"
                              rel=" noreferrer"
                            >
                              <i className="icon-facebook-f"></i>
                            </a>
                            <a
                              href={`https://www.instagram.com/${contact.instagram}`}
                              rel="noreferrer"
                              className="social-icon social-instagram"
                              title="Instagram"
                              target="_blank"
                            >
                              <i className="icon-instagram"></i>
                            </a>

                            {/* <Link to="#" className="social-icon social-twitter" title="Twitter" target="_blank"><i className="icon-twitter"></i></Link>
<Link to="#" className="social-icon social-instagram" title="Instagram" target="_blank"><i className="icon-instagram"></i></Link>
<Link to="#" className="social-icon social-youtube" title="Youtube" target="_blank"><i className="icon-youtube"></i></Link>
<Link to="#" className="social-icon social-pinterest" title="Pinterest" target="_blank"><i className="icon-pinterest"></i></Link> */}
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
              </div>

              <hr className="mt-3 mb-5 mt-md-1" />
              <div className="touch-container row justify-content-center">
                <div className="col-md-9 col-lg-7">
                  <div className="text-center">
                    <h2 className="title text-primary mb-1">Get In Touch</h2>
                    <p className="lead">
                      Sunday: 10am to 4pm <br />
                      Monday: Appointment Only* <br />
                      Tuesday: Appointment Only* <br />
                      Wednesday: 10am to 6pm <br />
                      Thursday: 10am to 6pm <br />
                      Friday: 10am to 6pm <br />
                      Saturday: 10am to 4pm <br />
                    </p>
                    <p className="mb-3">
                      If you have feedback, questions or requests, please
                      complete and submit the form below.
                    </p>
                  </div>

                  {this.state.status === "init" && (
                    <form
                      action="#"
                      className="contact-form mb-3"
                      name="contact"
                      method="POST"
                      data-netlify="true"
                      netlify-honeypot="bot-field"
                      data-netlify-recaptcha="true"
                      onSubmit={this.handleSubmit}
                    >
                      <input type="hidden" name="form-name" value="contact" />
                      <input
                        type="hidden"
                        name="bot-field"
                        onChange={this.handleChange}
                      />
                      <div className="row">
                        <div className="col-sm-6">
                          <label htmlFor="firstname" className="sr-only">
                            First Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="firstname"
                            name="firstName"
                            placeholder="Enter your first name"
                            required=""
                            value={firstName}
                            onChange={this.handleChange}
                          />
                        </div>{" "}
                        <div className="col-sm-6">
                          <label htmlFor="lastname" className="sr-only">
                            Last Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastname"
                            name="lastName"
                            placeholder="Enter your last name"
                            required=""
                            value={lastName}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <label htmlFor="cemail" className="sr-only">
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="cemail"
                            name="email"
                            placeholder="Email"
                            required=""
                            value={email}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-6">
                          <label htmlFor="ccontact" className="sr-only">
                            contact
                          </label>
                          <input
                            type="tel"
                            className="form-control"
                            id="ccontact"
                            name="contact"
                            placeholder="Enter your number"
                            required=""
                            value={contact}
                            onChange={this.handleChange}
                          />
                        </div>

                        <div className="col-sm-6">
                          <label htmlFor="subject" className="sr-only">
                            Subject
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            rows="6"
                            placeholder="Write Your Subject"
                            id="subject"
                            name="subject"
                            value={subject}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <label htmlFor="cmessage" className="sr-only">
                        Message
                      </label>
                      <textarea
                        className="form-control"
                        rows="4"
                        cols="30"
                        placeholder="Write Your Message"
                        id="cmessage"
                        name="message"
                        value={message}
                        onChange={this.handleChange}
                      ></textarea>
                      <div className="col-md-12 mb-4">
                        <Recaptcha
                          sitekey={`${process.env.GATSBY_SITE_RECAPTCHA_KEY}`}
                          render="explicit"
                          onloadCallback={() => {}}
                          verifyCallback={(res) =>
                            this.setState({ recaptcha: res })
                          }
                        />
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-outline-primary-2 btn-minwidth-sm"
                            disabled={
                              this.state.status === "submitting" ||
                              this.state.recaptcha === undefined
                            
                          }
                        >
                          <span>SUBMIT</span>
                          <i className="icon-long-arrow-right"></i>
                        </button>
                      </div>
                    </form>
                  )}

                  {this.state.status === "success" && (
                    <div className="container">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="alert " role="alert">
                            <h4 className="alert-heading">
                              Thank you for contacting us!
                            </h4>
                            <p>We will get back to you as soon as possible.</p>

                            <hr />

                            <Link to="/" className="btn btn-outline-primary-2">
                              <span> Back to Home</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {this.state.status === "failed" && (
                    <div className="success-text">
                      <i
                        className="fa fa-times-circle"
                        aria-hidden="true"
                        style={{ color: "#ff4c3b" }}
                      ></i>
                      <h2>Oops!</h2>
                      <p>
                        Something went wrong while submitting the response.
                        Please refresh the page and try again.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}

export default React.memo(ContactUs);
